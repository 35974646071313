<template>
    <el-dialog
        :title="`Adauga factura la comanda ${orderId}`"
        visible
        width="50%"
        :show-close="false"
        :close-on-click-modal="false"
    >
        <div>
            <el-form ref="modalForm" :model="modalForm" :rules="formRules" label-position="top" size="small">
                <el-form-item label="Serie" prop="invSeries">
                    <el-input v-model="modalForm.invSeries" :disabled="isLoading"></el-input>
                </el-form-item>
                <el-form-item label="Numar" prop="invNumber">
                    <el-input v-model="modalForm.invNumber" :disabled="isLoading"></el-input>
                </el-form-item>
            </el-form>
            <div class="separator"></div>
            <Dropzone
                v-show="!isLoading"
                :config="dropzoneConfig"
                :drop-message="`Trage aici documentul!`"
                ref="dzone"
            ></Dropzone>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button :disabled="isLoading" @click="closeDialog">Inchide</el-button>
            <el-button type="primary" @click="saveInvoice" :loading="isLoading">Incarca </el-button>
        </span>
    </el-dialog>
</template>

<script>
import { sync, call, get } from 'vuex-pathify';
import { EventBus } from '@/utils/Bus.js';

const DropzoneComp = () => import('@/components/Dropzone/Index.vue');

export default {
    props: ['order'],
    components: {
        Dropzone: DropzoneComp,
    },
    data() {
        return {
            modalForm: {
                invSeries: '',
                invNumber: '',
            },
            dropzoneConfig: {
                acceptedFiles: '.jpg,.gif,.jpeg,.png,.pdf',
                maxFilesize: 3,
                maxNumberOfFiles: 1,
                maxFiles: 1,
            },
            isLoading: false,
        };
    },
    methods: {
        addOrderInvoice: call('sales/AddOrderInvoice'),
        closeDialog() {
            this.dialogInsert = false;
        },
        async saveInvoice() {
            const files = this.$refs?.dzone?.$refs?.dropzoneComp?.getAcceptedFiles() ?? [];
            if (files == null || files.length == 0) {
                this.$store.dispatch('snackbar/addMessage', {
                    type: 'error',
                    message: `Selectati un document!`,
                });
                return false;
            }
            const file = files?.[0];

            this.$refs['modalForm'].validate(async (valid) => {
                console.log('valid', valid);
                if (!valid) {
                    return false;
                }

                const confirm = await this.$confirm(
                    `Sigur adaugati o noua factura pentru comanda ${this.orderId}?`,
                    'Atentie',
                    {
                        confirmButtonText: 'Da',
                        cancelButtonText: 'Nu',
                        type: 'warning',
                    }
                ).catch(() => false);
                if (!confirm) {
                    return;
                }

                try {
                    this.isLoading = true;
                    const formData = new FormData();

                    formData.append('file', file);
                    formData.append('oid', this.orderId);
                    formData.append('series', this.modalForm.invSeries);
                    formData.append('number', this.modalForm.invNumber);
                    await this.addOrderInvoice(formData);
                    this.$store.dispatch('snackbar/addMessage', {
                        type: 'success',
                        message: `Factura a fost adaugata cu succes!`,
                    });
                    EventBus.$emit('invoiceEvent');
                    this.closeDialog();
                } catch (e) {
                    console.log('e', e);
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'saveInvoice',
                        },
                    });
                } finally {
                    this.isLoading = false;
                }

                return;
            });
        },
    },
    computed: {
        dialogInsert: sync('sales/order@invoices.dialogInsert'),

        orderId() {
            return this.order.orderId;
        },
        formRules() {
            return {
                invSeries: [{ required: true, message: 'Acest camp este obligatoriu', trigger: 'blur' }],
                invNumber: [{ required: true, message: 'Acest camp este obligatoriu', trigger: 'blur' }],
            };
        },
    },
    beforeDestroy() {},
};
</script>
